<template>
    <Modal small noClose ref="modal" qa-name="sandbox-processing-modal">
        <div class="flex items-center flex-col">
            <h2 class="font-bold text-center font-walsheim text-2xl mb-8 leading-none">We are processing your request...</h2>
            <Spinner class="mb-8 w-[80px] h-[80px]"/>
            <p class="text-sm">This may take a few minutes. Please do not close your window or reload the page.</p>
        </div>
    </Modal>
</template>

<script>

import Modal from '@/Components/Modal.vue';
import Spinner from '@/BuyNow/Components/Spinner.vue';

export default {
    components: {
        Modal,
        Spinner,
    },
    props: {
    },
    data() {
        return {

        }
    },
    mounted() {
        this.$refs.modal.openModal();
    },
    computed: {
    },
    methods: {
    }
};
</script>

<style lang="scss" scoped>
</style>
