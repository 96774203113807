import '../bootstrap';
import '../../css/app.css';
import 'floating-vue/dist/style.css';

import { createApp } from 'vue';
import { store } from '@/Sandbox/store';
import FloatingVue from 'floating-vue'

import App from '@/Sandbox/Pages/Sandbox.vue';

// To avoid sentry hurting our web vitals we delay its load by 5 seconds
if (!window.Sentry.loaded && !!Keap.env.VITE_ENABLE_SENTRY && Keap.env.VITE_ENABLE_SENTRY.toLowerCase() === 'true') {
    loadSentry();
}

async function loadSentry() {
    const module = await import('@/Util/sentry.js');
    window.Sentry = new module.Sentry();
}

const app = createApp(App);

app.use(FloatingVue, {
    themes: {
        'info-tooltip': {
            '$extend': 'dropdown',
            '$resetCss': true,
            placement: 'bottom-start',
            triggers: [
                'hover',
                'focus',
                'click',
                'touch',
            ],
            autoHide: false,
            placement: 'bottom',
            html: true,
        },
    },
});
app.use(store);

app.mount("#app");
