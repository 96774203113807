import axios from 'axios';

export default async (commit, getters) => {
    let { billingPeriod, selectedPlanId} = getters.cartInfo;
    let includedAddons = getters.includedAddons.map(({itemId, quantity}) => {
        return {
            itemId,
            quantity
        }
    });
    let includedCharges = getters.includedCharges.map(({itemId, quantity}) => {
        return {
            itemId,
            quantity
        }
    });

    let payload = {
        plan: selectedPlanId,
        billingPeriod: 1,
        billingPeriodUnit: billingPeriod,
        addons: [
            ...includedAddons
        ],
        charges: [
            ...includedCharges
        ],
        userInfo: {
            firstName: getters.userInfo.firstName,
            lastName: getters.userInfo.lastName,
            email: getters.userInfo.email,
            phone: getters.userInfo.phone,
            currency: 'USD',
            paymentToken: getters.userInfo.paymentToken,
        },
        billingAddress: {
            line1: getters.userInfo.line1,
            line2: getters.userInfo.line2,
            city: getters.userInfo.city,
            stateCode: getters.userInfo.state,
            postalCode: getters.userInfo.postalCode,
            countryCode: getters.userInfo.country,
        },
        talon: getters.talon,
        tracking: JSON.stringify(getters.tracking),
    };

    try {
        let { data } = await axios.post(`/api/v1/subscription/create/sandbox`, {
            ...payload
        });
        await commit('updateUserInfo', {
            subscriptionId: data.subscriptionId
        });
        await commit('setSubmitting', false);
        await commit('setSubmittedSuccess', true);
    } catch (error) {
        commit('setSubmitting', false);
        if (window.axios.isAxiosError(error)) {
            if (!!error.response) {
                const errorObj = error.response.data;
                window.Sentry.logError(error, {server_event_id: error.response.data.eventId});
                commit('setError', {
                    active: true,
                    code: errorObj.error,
                    message: errorObj.message
                });
            } else {
                window.Sentry.logError(error);
                commit('setError', {
                    active: true,
                    code: window.constants.errorCodes.generalError,
                    message: window.constants.errorMessages.generalError
                });
            }
        } else if (!getters.hasActiveError) {
            window.Sentry.logError(error);
            commit('setError', {
                active: true,
                code: window.constants.errorCodes.generalError
            });
        }
    }
}
