<template>
    <div class="field-grid">
        <InputField
            key="firstName"
            v-model="firstName"
            :value="firstName"
            data-qa="firstName-field"
            :valid="firstNameValid"
            field-label="First name"
            field-type="text"
            required
            :readonly="$store.getters.isUpgrading || $store.getters.isAddingSub"
            class="col-span-2 md:col-span-1"
        >
            <template v-slot:error>
                <p v-text="firstNameError"></p>
            </template>
        </InputField>
        <InputField
            key="lastName"
            v-model="lastName"
            :value="lastName"
            data-qa="lastName-field"
            :valid="lastNameValid"
            field-label="Last name"
            field-type="text"
            required
            :readonly="$store.getters.isUpgrading || $store.getters.isAddingSub"
            class="col-span-2 md:col-span-1"
        >
            <template v-slot:error>
                <p v-text="lastNameError"></p>
            </template>
        </InputField>
        <InputField
            key="email"
            v-model="email"
            :value="email"
            data-qa="email-field"
            :valid="emailValid"
            field-label="Email"
            field-type="text"
            required
            :readonly="$store.getters.isUpgrading || $store.getters.isAddingSub"
            class="col-span-2 md:col-span-1"
        >
            <template v-slot:error>
                <p v-text="emailError"></p>
            </template>
        </InputField>
        <PhoneField
            key="phone"
            v-model="phone"
            :value="phone"
            data-qa="phone-field"
            :valid="phoneValid"
            field-label="Phone"
            field-type="text"
            required
            :readonly="($store.getters.isUpgrading || $store.getters.isAddingSub) && hasPhoneNumber"
            class="col-span-2 md:col-span-1"
        >
            <template v-slot:error>
                <p v-text="phoneError"></p>
            </template>
        </PhoneField>
    </div>
</template>

<script>
import InputField from '@/BuyNow/Components/inputs/InputField.vue';
import PhoneField from '@/BuyNow/Components/inputs/PhoneField.vue';
import { EMAIL_REGEX, PHONE_REGEX } from '@/Util/constants.js';

export default {
    components: {
        PhoneField,
        InputField,
    },
    data() {
        return {
            hasPhoneNumber: true,
        };
    },
    mounted() {
        if (!this.$store.getters.userInfo.phone) {
            this.hasPhoneNumber = false;
        }
    },
    updated() {
    },
    computed: {
        firstName: {
            get() {
                return this.$store.state.userInfo.firstName;
            },
            set(value) {
                this.$store.commit('updateUserInfo', { firstName: value });
            }
        },
        firstNameValid: {
            get() {
                return this.$store.state.validation.firstName.valid;
            },
            set(value) {;
                this.$store.commit('updateValidation', { firstName: { valid: value, message: this.firstNameError }});
            }
        },
        firstNameError: {
            get() {
                return this.$store.state.validation.firstName.message;
            },
            set(value) {
                this.$store.commit('updateValidation', { firstName: { valid: this.firstNameValid, message: value }});
            }
        },
        lastName: {
            get() {
                return this.$store.state.userInfo.lastName;
            },
            set(value) {
                this.$store.commit('updateUserInfo', { lastName: value });
            }
        },
        lastNameValid: {
            get() {
                return this.$store.state.validation.lastName.valid;
            },
            set(value) {
                this.$store.commit('updateValidation', { lastName: { valid: value, message: this.lastNameError }});
            }
        },
        lastNameError: {
            get() {
                return this.$store.state.validation.lastName.message;
            },
            set(value) {
                this.$store.commit('updateValidation', { lastName: { valid: this.lastNameValid, message: value }});
            }
        },
        email: {
            get() {
                return this.$store.state.userInfo.email;
            },
            set(value) {
                this.$store.commit('updateUserInfo', { email: value });
            }
        },
        emailValid: {
            get() {
                return this.$store.state.validation.email.valid;
            },
            set(value) {
                this.$store.commit('updateValidation', { email: { valid: value, message: this.emailError }});
            }
        },
        emailError: {
            get() {
                return this.$store.state.validation.email.message;
            },
            set(value) {
                this.$store.commit('updateValidation', { email: { valid: this.emailValid, message: value }});
            }
        },
        phone: {
            get() {
                return this.$store.state.userInfo.phone;
            },
            set(value) {
                this.$store.commit('updateUserInfo', { phone: value });
            }
        },
        phoneValid: {
            get() {
                return this.$store.state.validation.phone.valid;
            },
            set(value) {
                this.$store.commit('updateValidation', { phone: { valid: value, message: this.phoneError }});
            }
        },
        phoneError: {
            get() {
                return this.$store.state.validation.phone.message;
            },
            set(value) {
                this.$store.commit('updateValidation', { phone: { valid: this.phoneValid, message: value }});
            }
        },
    },
    watch: {
        // Form validation
        firstName(newVal, oldVal) {
            if (newVal == '') {
                this.firstNameValid = false;
                this.firstNameError = 'First name is required';
            } else {
                this.firstNameValid = true;
            }
        },
        lastName(newVal, oldVal) {
            if (newVal == '') {
                this.lastNameValid = false;
                this.lastNameError = 'Last name is required';
            } else {
                this.lastNameValid = true;
            }
        },
        email(newVal, oldVal) {
            if (newVal == '') {
                this.emailValid = false;
                this.emailError = 'Email is required';
            } else if (!RegExp(EMAIL_REGEX).test(newVal)) {
                this.emailValid = false;
                this.emailError = 'Email is not valid';
            } else {
                this.emailValid = true;
            }
        },
        phone(newVal, oldVal) {
            if (newVal == '') {
                this.phoneValid = false;
                this.phoneError = 'Phone is required';
            } else if (!RegExp(PHONE_REGEX).test(newVal)) {
                this.phoneValid = false;
                this.phoneError = 'Phone is not valid';
            } else {
                this.phoneValid = true;
            }
        },
    }
};
</script>

<style lang="postcss" scoped>
</style>
