<template>
    <div class="flex-1 bg-white rounded-medium p-7 pb-0 md:pb-7">
        <h1 class="font-walsheim text-lg mb-6">Select your plan</h1>
        <PlanSection class="mb-6"/>
        <p class="text-2xs mb-12">Sandbox apps may be deleted due to inactivity.</p>
        <form ref="paymentForm" @submit.prevent="submitSignup()">
            <h2 class="font-walsheim text-lg mb-6">Enter your info</h2>
            <UserInfo class="mb-6" />
            <h3 class="font-walsheim text-base mb-4">Billing address</h3>
            <p class="text-xs mb-4">Since these sandbox applications are for development and testing purposes we are able to offer these sandbox applications at <strong>*no charge</strong>. The only time a charge would be incurred to the credit card on file would be for the use of facsimile services.</p>
            <Address class="mb-6" />
            <h3 class="font-walsheim text-base mb-4">Card info</h3>
            <p class="text-xs mb-4">You will <strong class="underline">not</strong> be charged today. We collect credit card information to allow the opportunity to test fax functionality. The fee for fax is $0.15/fax.</p>
            <CardDetails ref="cardDetails" class="mb-6" />
            <EhawkTalon ref="ehawkTalon" />
            <div class="w-full flex gap-6 md:flex-row flex-col">
                <KeapButton @click.prevent="submitSignup()" large xs small-expanded data-qa="create-app-button" :disabled="!canSubmit" class="shrink-0">
                    Create app
                </KeapButton>
                    <p class="text-2xs">* No charge for the monthly service. There may be overage or usage rates that apply for facsimiles that are sent.</p>
            </div>
        </form>
    </div>
</template>

<script>
import PlanSection from '@/Sandbox/Components/PlanSection.vue';
import UserInfo from '@/Sandbox/Components/inputs/Groups/UserInfo.vue';
import Address from '@/Sandbox/Components/inputs/Groups/Address.vue';
import CardDetails from '@/Sandbox/Components/inputs/Groups/CardDetails.vue';
import EhawkTalon from '@/BuyNow/Components/inputs/EhawkTalon.vue';
import KeapButton from '@/Components/KeapButton.vue';

export default {
    components: {
        PlanSection,
        UserInfo,
        Address,
        CardDetails,
        EhawkTalon,
        KeapButton,
    },
    mounted() {
    },
    methods: {
        async submitSignup() {
            window.Sentry.setUser(this.$store.getters.userInfo.email);
            this.$store.commit('setSubmitting', true);
            await this.$refs.ehawkTalon.updateTalon();
            await this.$refs.cardDetails.tokenize();
            await this.$store.dispatch('submitSignup');
        }
    },
    computed: {
        canSubmit() {
            return !this.$store.getters.isSoftLoading && this.$store.getters.canSubmit;
        },
    },
};
</script>

<style lang="postcss" scoped>
</style>
