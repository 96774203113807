<template>
    <Component
        v-if="hasError"
        :is="modalComponent"
        :noClose="noClose"
        :qa-name="`${toKebab(modalComponent)}_modal`"
        @closeModal="closeModal"
    />
</template>

<script>
import { defineAsyncComponent } from 'vue';

const Blocked = defineAsyncComponent(() => import('@/Sandbox/Components/Modals/Blocked.vue'));
const General = defineAsyncComponent(() => import('@/Sandbox/Components/Modals/General.vue'));
const PayNow = defineAsyncComponent(() => import('@/Sandbox/Components/Modals/PayNow.vue'));

export default {
    components: {
        Blocked,
        General,
        PayNow,
    },
    props: {
        noClose: {
            type: Boolean
        }
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
        closeModal() {
            this.$store.commit('setError', {
                active: false,
                code: 0
            });
        },
        toKebab(string) {
            return string.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
        }
    },
    computed: {
        hasError() {
            return this.$store.getters.hasActiveError;
        },
        modalComponent() {
            const ERRORS = window.constants.errorCodes;
            if (this.$store.getters.hasActiveError) {
                switch(this.$store.getters.errorCode) {
                    case ERRORS.ehawkBlocked:
                    case ERRORS.recaptchaBlocked:
                        return 'Blocked';
                    case ERRORS.paymentException:
                        return 'PayNow';
                    default:
                        return 'General';
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
