<template>
    <div class="w-full h-full fixed bg-white top-0 left-0 z-50 flex justify-center items-center" data-qa="link-error">
        <div class="flex flex-col gap-7 mx-auto max-w-[938px] pb-14 md:pb-36">
            <SadBrowser class="w-[120px] h-[120px] mx-auto" />
            <h1 class="text-center text-xl" data-qa="link-error-title">We're experiencing temporary issues</h1>
            <div class="flex justify-center flex-col gap-[inherit]">
                <p class="text-center text-lg" data-qa="link-error-text">Please contact support for help.</p>
                <Link class="mx-auto" href="{{ supportNumber }}" data-qa="link-error-cta">{{ supportNumberDisplay }}</Link>
            </div>
        </div>
    </div>
</template>

<script>
import SadBrowser from '@/../svg/Icon_Sad-Browser.svg?component';
import Link from '@/Components/Link.vue';
import { SUPPORT_PHONE_NUMBER} from '@/Util/constants.js';

export default {
    components: {
        SadBrowser,
        Link,
    },
    props: {
    },
    data() {
        return {
        }
    },
    mounted() {
        this.$store.commit('setInitialLoading', false);
    },
    methods: {
    },
    computed: {
        supportNumber() {
            return `tel:${SUPPORT_PHONE_NUMBER}`;
        },
        supportNumberDisplay() {
            return SUPPORT_PHONE_NUMBER;
        },
    }
};
</script>

<style lang="scss" scoped>
</style>
