<template>
    <div class="grid gap-4 grid-cols-1 md:grid-cols-1">
        <PlanCard v-for="plan in plans" :key="plan.id" :plan="plan"/>
    </div>
</template>

<script>
import PlanCard from '@/Sandbox/Components/PlanCard.vue';

export default {
    data() {
        return {
            accordionOpen: true
        };
    },
    components: {
        PlanCard,
    },
    mounted() {
    },
    props: {
    },
    methods: {
    },
    created() {
    },
    computed: {
        gridColClass() {
            if (this.plans.length <= 1) {
                return '';
            } else if (this.plans.length === 2) {
                return 'md:grid-cols-1';
            } else {
                return 'md:grid-cols-3';
            }
        },
        plans() {
            const plans = this.$store.getters.plans;
            return plans;
        }
    },
    watch: {
    }
};
</script>

<style lang="postcss" scoped>
</style>
