<template>
    <div class="flex-1 bg-white rounded-medium p-7 pb-0 md:pb-7 text-center">
        <div class="max-w-xl mx-auto flex justify-center items-center flex-col">
            <IconCongrats class="mb-6 w-[80px] h-[80px]" />
            <h1 class="font-walsheim text-xl font-bold mb-6">Welcome to Keap, {{ $store.getters.userInfo.firstName }}!</h1>
            <p class="text-sm font-normal mb-3">Sandbox details sent to: <span class="font-medium">{{ $store.getters.userInfo.email }}</span></p>
            <p class="text-sm font-normal mb-6">Selected sandbox plan: <span class="font-medium">{{ planName }}</span></p>
            <p class="text-normal">If you need to change your email with us, or have questions about your subscription, contact support via <a href="https://help.keap.com/help/contact-support" class="underline whitespace-nowrap" target="_blank">https://help.keap.com/help/contact-support</a>.</p>
        </div>

        <!-- Free form area -->
        <!-- TODO: Eventually this section can be moved over to craft. -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-7 md:gap-5 mt-12 max-w-3xl mx-auto">
            <DeveloperDocs />
        </div>
    </div>
</template>

<script>
import IconCongrats from '@/../svg/Icon_Congrats.svg?component';
import Bullet from '@/../svg/Bullet.svg?component';
import Link from '@/Components/Link.vue';
import KeapButton from '@/Components/KeapButton.vue';
import { priceFormat, _toTitleCase } from '@/Util/helpers.js';

import DeveloperDocs from '@/Sandbox/Components/FreeForm/DeveloperDocs.vue';


export default {
    data() {
        return {
        }
    },
    components: {
        IconCongrats,
        Bullet,
        Link,
        KeapButton,
        DeveloperDocs,
    },
    mounted() {
    },
    props: {
    },
    methods: {
        _toTitleCase,
        priceFormat
    },
    created() {
    },
    computed: {
        planName() {
            return this.$store.getters.selectedPlan.metadata.title || this.$store.getters.selectedPlan.name
        },
    },
};
</script>

<style lang="postcss" scoped>
</style>
