export default {
    updateUserInfo(state, userInfo) {
        state.userInfo = { ...state.userInfo, ...userInfo };
    },
    updatePurchaseInfo(state, purchaseInfo) {
        state.purchaseInfo = { ...state.purchaseInfo, ...purchaseInfo };
    },
    updateCartInfo(state, cartInfo) {
        state.cartInfo = { ...state.cartInfo, ...cartInfo };
    },
    updateTalon(state, value) {
        state.talon = value;
    },
    updateTracking(state, tracking) {
        state.tracking = { ...state.tracking, ...tracking };
    },
    updateValidation(state, data) {
        state.validation = { ...state.validation, ...data };
    },
    addAttachment(state, { itemId, quantity }) {
        const selectedPlan = state.cartInfo.pricing.plans.find(plan => plan.id == state.cartInfo.selectedPlanId);

        if (selectedPlan.addons.find(item => item.itemId == itemId)) {
            state.cartInfo.selectedAddons.push({ itemId, quantity });
        }
        if (selectedPlan.charges.find(item => item.itemId == itemId)) {
            state.cartInfo.selectedCharges.push({ itemId, quantity });
        }
    },
    updateAttachment(state, { itemId, quantity }) {
        const selectedPlan = state.cartInfo.pricing.plans.find(plan => plan.id == state.cartInfo.selectedPlanId);

        if (selectedPlan.addons.find(item => item.itemId == itemId)) {
           state.cartInfo.selectedAddons.forEach((item) => {
                if (item.itemId == itemId) {
                    item.quantity = quantity;
                }
            })
        }
        if (selectedPlan.charges.find(item => item.itemId == itemId)) {
            state.cartInfo.selectedCharges.forEach((item) => {
                if (item.itemId == itemId) {
                    item.quantity = quantity;
                }
            })
        }
    },
    removeAttachment(state, value) {
        const selectedPlan = state.cartInfo.pricing.plans.find(plan => plan.id == state.cartInfo.selectedPlanId);

        if (selectedPlan.addons.find(item => item.itemId == value)) {
            state.cartInfo.selectedAddons = state.cartInfo.selectedAddons.filter(item => {
                return item.itemId != value;
            });
        }
        if (selectedPlan.charges.find(item => item.itemId == value)) {
            state.cartInfo.selectedCharges = state.cartInfo.selectedCharges.filter(item => {
                return item.itemId != value;
            });
        }
    },
    setOriginalQuantity(state, {planId, attachmentId, quantity}) {
        let foundPlanId = state.cartInfo.originalQuantities.find((val, index) => {
            return index === planId;
        });
        if (!foundPlanId) {
            state.cartInfo.originalQuantities.push({
                [planId]: {
                    [attachmentId]: quantity
                }
            });
            return;
        }
        let foundAttachmentId = state.cartInfo.originalQuantities[planId].find((val, index) => {
            return index === attachmentId;
        });
        if (!!foundPlanId && !foundAttachmentId) {
            state.cartInfo.originalQuantities[planId].push({
                [attachmentId]: quantity
            });
            return;
        }

    },
    setSubmittingContactSales(state, value) {
        state.orderStatus.submittingContactSales = value;
    },
    setSubmitting(state, value) {
        state.orderStatus.submitting = value;
    },
    setSubmittedSuccess(state, value) {
        state.orderStatus.success = value;
    },
    setBillingPeriod(state, value) {
        state.cartInfo.billingPeriod = value;
    },
    setCurrentStep( state, value ) {
        state.currentStep = value;
    },
    setEstimateData( state, value ) {
        state.estimate = value;
    },
    setInitialLoading( state, value ) {
        state.initialLoading = value;
    },
    setLoaded( state, value ) {
        state.loaded = value;
    },
    setSoftLoaded( state, value ) {
        state.softLoading = value;
    },
    setLinkError(state, error) {
        state.linkError = { ...state.linkError, ...error };
    },
    setError(state, error) {
        state.error = { ...state.error, ...error };
    },
    setPricing( state, data ) {
        state.cartInfo.pricing = data;
    },
    setIsUpgrading( state, getters ) {
        state.purchaseInfo.isUpgrading = state;
    },
    setIsAddingSub( state, getters ) {
        state.purchaseInfo.isAddingSub = state;
    },
    contactedSales( state, data ) {
        state.orderStatus.contactedSales = data;
    },
    updateTempAttachmentStorage( state, data ) {
        if (state.cartInfo.tempStorage.attachments.find(item => item.itemId === data.itemId)) {
            state.cartInfo.tempStorage.attachments.forEach(item => {
                if (item.itemId === data.itemId) {
                    item.quantity = data.quantity;
                }
            })
        } else {
            state.cartInfo.tempStorage.attachments.push(data);
        }
    },
    clearTempAttachmentStorage( state ) {
        state.cartInfo.tempStorage.attachments = [];
    },
    setHasFetchedEstimate( state, value ) {
        state.hasFetchedEstimate = value;
    }
};
