import {
    createStore
} from 'vuex';

// Get local store
import actions from '@/Sandbox/store/actions.js';
import state from '@/Sandbox/store/state.js';
import mutations from '@/Sandbox/store/mutations.js';
import getters from '@/Sandbox/store/getters.js';

export const store = createStore({
    state,
    mutations,
    actions,
    getters,
    strict: process.env.NODE_ENV !== 'production'
})
