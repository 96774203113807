import { getBestTier } from '@/Util/helpers.js';

export default {
    selectedCurrency( state ) {
        return state.userInfo.currency;
    },
    billingPeriod( state ) {
        return state.cartInfo.billingPeriod;
    },
    tracking( state ) {
        return state.tracking;
    },
    isUpgrading( state, getters ) {
        return state.purchaseInfo.isUpgrading;
    },
    isAddingSub( state, getters ) {
        return state.purchaseInfo.isAddingSub;
    },
    currencyPrefix( state ) {
        switch(state.userInfo.currency) {
            case 'GBP':
                return '£';
            case 'AUD':
                return 'A$';
            default:
                return '$';
        }
    },
    isYearly( state ) {
        return state.cartInfo.billingPeriod == 'year';
    },
    isMonthly( state ) {
        return state.cartInfo.billingPeriod == 'month';
    },
    currentStep( state ) {
        return state.currentStep;
    },
    initialLoading( state ) {
        return state.initialLoading;
    },
    purchaseInfo( state ) {
        return state.purchaseInfo;
    },
    isPartnerLink( state ) {
        return !!state.purchaseInfo.partnerAccountId;
    },
    isPurchaseLink( state ) {
        return !!state.purchaseInfo.purchaseLinkId;
    },
    isCuratedLink( state ) {
        return !!state.purchaseInfo.partnerAccountId || !!state.purchaseInfo.purchaseLinkId;
    },
    includedAddons( state ) {
        const selectedPlan = state.cartInfo.pricing.plans.find(item => {
            return item.id === state.cartInfo.selectedPlanId || item.aliases.includes(state.cartInfo.selectedPlanId);
        });
        return selectedPlan.addons.filter(addon => addon.type === 'mandatory');
    },
    includedCharges( state ) {
        const selectedPlan = state.cartInfo.pricing.plans.find(item => {
            return item.id === state.cartInfo.selectedPlanId || item.aliases.includes(state.cartInfo.selectedPlanId);
        });
        return selectedPlan.charges.filter(addon => addon.type === 'mandatory');
    },
    optionalAddons( state ) {
        const selectedPlan = state.cartInfo.pricing.plans.find(item => {
            return item.id === state.cartInfo.selectedPlanId || item.aliases.includes(state.cartInfo.selectedPlanId);
        });
        return selectedPlan.addons.filter(addon => addon.type !== 'mandatory');
    },
    optionalCharges( state ) {
        const selectedPlan = state.cartInfo.pricing.plans.find(item => {
            return item.id === state.cartInfo.selectedPlanId || item.aliases.includes(state.cartInfo.selectedPlanId);
        });
        return selectedPlan.charges.filter(charge => charge.type !== 'mandatory');
    },
    userInfo( state ) {
        return state.userInfo;
    },
    cartInfo( state ) {
        return state.cartInfo;
    },
    plans( state ) {
        return state.cartInfo.pricing.plans.filter((plan) => {
            return plan.metadata.showInCart && !plan.metadata.hideInUserView;
        });
    },
    allPlans( state ) {
        return state.cartInfo.pricing.plans;
    },
    coupons( state ) {
        return state.cartInfo.pricing.coupons;
    },
    invalidCoupons( state, getters ) {
        let allCoupons = getters.coupons.map(coupon => {
            return coupon.id;
        });
        let invalidCoupons = state.cartInfo.coupons.filter(coupon => {
            return !allCoupons.includes(coupon);
        });
        if (invalidCoupons[0] === 'CURRENT') {
            return [];
        }
        return invalidCoupons;
    },
    itemCoupons( state, getters ) {
        return getters.coupons.filter(coupon => {
            return coupon.applyOn === 'each_specified_item';
        });
    },
    invoiceCoupons( state, getters ) {
        return getters.coupons.filter(coupon => {
            return coupon.applyOn === 'invoice_amount';
        });
    },
    hasActiveError( state ) {
        return state.error.active;
    },
    hasActiveLinkError( state ) {
        return state.linkError.active;
    },
    errorCode( state ) {
        return state.error.code;
    },
    errorMessage( state ) {
        return state.error.message;
    },
    selectedPlanId( state ) {
        return state.cartInfo.selectedPlanId;
    },
    selectedPlan( state ) {
        return state.cartInfo.pricing.plans.find((plan) => {
            return state.cartInfo.selectedPlanId === plan.id;
        });
    },
    selectedPlanPriceId( state, getters ) {
        return getters.selectedPlan.pricing.find(price => price.billingPeriodUnit === getters.billingPeriod).id;
    },
    isLoaded( state ) {
        return state.loaded;
    },
    isSoftLoading( state ) {
        return state.softLoading;
    },
    tempStorage( state ) {
        return state.cartInfo.tempStorage;
    },
    orderComplete( state ) {
        return state.orderStatus.success;
    },
    contactedSales( state ) {
        return state.orderStatus.contactedSales;
    },
    isSubmittingContactSales( state ) {
        return state.orderStatus.submittingContactSales;
    },
    isSubmitting( state ) {
        return state.orderStatus.submitting;
    },
    appLink( state ) {
        return `${Keap.env.VITE_KEAP_APP_URL}?app_id=${state.userInfo.tenantId || ''}`;
    },
    estimate( state ) {
        return state.estimate;
    },
    tax( state ) {
        return (state.estimate.tax || 0) / 100;
    },
    totals( state, getters ) {
        let subTotal = 0;
        let oneTimeDiscountTotal = 0;
        let limitedPeriodDiscountTotal = 0;
        let everGreenDiscountTotal = 0;
        let total = 0;
        let oneTimeChargesTotal = 0;

        const selectedPlan = getters.selectedPlan.pricing.find(price => price.billingPeriodUnit === getters.billingPeriod);
        const planCoupons = getters.itemCoupons.filter(coupon => {
            if (!!coupon.showForOnly && selectedPlan.coupons.includes(coupon.id)) {
                if (coupon.showForOnly.includes(getters.selectedPlanPriceId)) {
                    return true;
                } else {
                    return false;
                }
            }
            return selectedPlan.coupons.includes(coupon.id);
        });
        let planAmount = selectedPlan.tiers[0].unitPrice / 100;
        planCoupons.forEach(coupon => {
            let discountAmount = 0;
            if (coupon.type === 'fixed_amount') {
                discountAmount = coupon.amount / 100;
            }
            if (coupon.type === 'percentage') {
                discountAmount = planAmount * ((coupon.amount / 100) / 100);
            }
            if (coupon.duration === 'one_time') {
                oneTimeDiscountTotal += discountAmount;
            }
            if (coupon.duration === 'limited_period') {
                limitedPeriodDiscountTotal += discountAmount;
            }
            if (coupon.duration === 'forever') {
                everGreenDiscountTotal += discountAmount;
            }
        })
        subTotal += planAmount;

        const allAttachments = [...getters.selectedPlan.addons, ...getters.selectedPlan.charges];
        const addonPricing = state.cartInfo.selectedAddons.map(item => {
            let pricing = allAttachments.find(attachment => attachment.itemId === item.itemId).pricing;
            if (pricing.length > 1) {
                return {
                    ...pricing.find(price => price.billingPeriodUnit === getters.billingPeriod),
                    selectedQuantity: item.quantity
                };
            } else {
                return {
                    ...pricing[0],
                    selectedQuantity: item.quantity
                };
            }
        }).map(item => {
            let applicableCoupons = getters.itemCoupons.filter(coupon => {
                // TODO: We need to use the showForOnly feature in the coupon metadata.
                // if (!!coupon.showForOnly && item.coupons.includes(coupon.id)) {
                //     if (coupon.showForOnly.includes(getters.selectedPlanPriceId)) {
                //         return true;
                //     } else {
                //         return false;
                //     }
                // }
                // TODO: Test if the system applies this coupon or not when you check out with max. It shouldnt... I also need to check if the old cart applies it on checkout. Again it shouldnt...
                // !This is just a bypass to mimic the existing cart functionality. Long term this needs to be removed in favor of the "showForOnly" parameter
                if (getters.selectedPlanId.includes('keap-max')
                    && (
                        !!getters.cartInfo.selectedAddons.find(item => item.itemId.toLowerCase().includes('text-voice'))
                        && getters.cartInfo.selectedAddons.find(item => item.itemId.toLowerCase().includes('text-voice')).quantity === 1
                    )
                    && (
                        coupon.id.toLowerCase().includes('annual-3mons-freetier1-voice-and-text')
                        || coupon.id.toLowerCase().includes('3mos-freetier1-voice-and-text')
                    )
                ) {
                    return false;
                }
                return item.coupons.includes(coupon.id);
            });
            return {
                ...item,
                coupons: applicableCoupons
            }
        });
        const chargePricing = state.cartInfo.selectedCharges.map(item => {
            let pricing = allAttachments.find(attachment => attachment.itemId === item.itemId).pricing;
            if (pricing.length > 1) {
                return {
                    ...pricing.find(price => price.billingPeriodUnit === getters.billingPeriod),
                    selectedQuantity: item.quantity
                };
            } else {
                return {
                    ...pricing[0],
                    selectedQuantity: item.quantity
                };
            }
        }).map(item => {
            let applicableCoupons = getters.itemCoupons.filter(coupon => {
                if (!!coupon.showForOnly && item.coupons.includes(coupon.id)) {
                    if (coupon.showForOnly.includes(getters.selectedPlanPriceId)) {
                        return true;
                    } else {
                        return false;
                    }
                }
                return item.coupons.includes(coupon.id);
            });
            return {
                ...item,
                coupons: applicableCoupons
            }
        });
        // Loop through each selected addon and add it to the total
        addonPricing.forEach(item => {
            let itemTotal = 0;
            let discountAmount = 0;
            if (item.model === 'stairstep') {
                itemTotal = getBestTier(item.tiers, item.selectedQuantity).unitPrice / 100;
                item.coupons.forEach(coupon => {
                    if (coupon.type === 'fixed_amount') {
                        discountAmount = coupon.amount / 100;
                    }
                    if (coupon.type === 'percentage') {
                        discountAmount = itemTotal * ((coupon.amount / 100) / 100);
                    }
                    if (coupon.duration === 'one_time') {
                        oneTimeDiscountTotal += discountAmount;
                    }
                    if (coupon.duration === 'limited_period') {
                        limitedPeriodDiscountTotal += discountAmount;
                    }
                    if (coupon.duration === 'forever') {
                        everGreenDiscountTotal += discountAmount;
                    }
                });
            }
            if (item.model === 'per_unit') {
                itemTotal = item.selectedQuantity * (item.tiers[0].unitPrice / 100);
                item.coupons.forEach(coupon => {
                    if (coupon.type === 'fixed_amount') {
                        discountAmount = coupon.amount / 100;
                    }
                    if (coupon.type === 'percentage') {
                        discountAmount = itemTotal * ((coupon.amount / 100) / 100);
                    }
                    if (coupon.duration === 'one_time') {
                        oneTimeDiscountTotal += discountAmount;
                    }
                    if (coupon.duration === 'limited_period') {
                        limitedPeriodDiscountTotal += discountAmount;
                    }
                    if (coupon.duration === 'forever') {
                        everGreenDiscountTotal += discountAmount;
                    }
                });
            }
            if (item.model === 'flat_fee') {
                itemTotal = item.selectedQuantity * (item.tiers[0].unitPrice / 100);
                item.coupons.forEach(coupon => {
                    if (coupon.type === 'fixed_amount') {
                        discountAmount = coupon.amount / 100;
                    }
                    if (coupon.type === 'percentage') {
                        discountAmount = itemTotal * ((coupon.amount / 100) / 100);
                    }
                    if (coupon.duration === 'one_time') {
                        oneTimeDiscountTotal += discountAmount;
                    }
                    if (coupon.duration === 'limited_period') {
                        limitedPeriodDiscountTotal += discountAmount;
                    }
                    if (coupon.duration === 'forever') {
                        everGreenDiscountTotal += discountAmount;
                    }
                });
            }
            subTotal += itemTotal;
        });
        chargePricing.forEach(item => {
            let itemTotal = 0;
            let discountAmount = 0;
            if (item.model === 'stairstep') {
                itemTotal = getBestTier(item.tiers, item.selectedQuantity).unitPrice / 100;
                item.coupons.forEach(coupon => {
                    if (coupon.type === 'fixed_amount') {
                        discountAmount = coupon.amount / 100;
                    }
                    if (coupon.type === 'percentage') {
                        discountAmount = itemTotal * ((coupon.amount / 100) / 100);
                    }
                    if (coupon.duration === 'one_time') {
                        oneTimeDiscountTotal += discountAmount;
                    }
                    if (coupon.duration === 'limited_period') {
                        limitedPeriodDiscountTotal += discountAmount;
                    }
                    if (coupon.duration === 'forever') {
                        everGreenDiscountTotal += discountAmount;
                    }
                });
            }
            if (item.model === 'per_unit') {
                itemTotal = item.selectedQuantity * (item.tiers[0].unitPrice / 100);
                item.coupons.forEach(coupon => {
                    if (coupon.type === 'fixed_amount') {
                        discountAmount = coupon.amount / 100;
                    }
                    if (coupon.type === 'percentage') {
                        discountAmount = itemTotal * ((coupon.amount / 100) / 100);
                    }
                    if (coupon.duration === 'one_time') {
                        oneTimeDiscountTotal += discountAmount;
                    }
                    if (coupon.duration === 'limited_period') {
                        limitedPeriodDiscountTotal += discountAmount;
                    }
                    if (coupon.duration === 'forever') {
                        everGreenDiscountTotal += discountAmount;
                    }
                });
            }
            if (item.model === 'flat_fee') {
                itemTotal = item.selectedQuantity * (item.tiers[0].unitPrice / 100);
                item.coupons.forEach(coupon => {
                    if (coupon.type === 'fixed_amount') {
                        discountAmount = coupon.amount / 100;
                    }
                    if (coupon.type === 'percentage') {
                        discountAmount = itemTotal * ((coupon.amount / 100) / 100);
                    }
                    if (coupon.duration === 'one_time') {
                        oneTimeDiscountTotal += discountAmount;
                    }
                    if (coupon.duration === 'limited_period') {
                        limitedPeriodDiscountTotal += discountAmount;
                    }
                    if (coupon.duration === 'forever') {
                        everGreenDiscountTotal += discountAmount;
                    }
                });
            }
            oneTimeChargesTotal += itemTotal;
        });
        let invoiceDiscountTotal = {
            total: 0,
            oneTimeTotal: 0,
            limitedPeriodTotal: 0,
            everGreenTotal: 0,
        };

        getters.invoiceCoupons.forEach(coupon => {
            let discountAmount = 0;
            if (coupon.type === 'fixed_amount') {
                discountAmount = coupon.amount / 100;
            }
            if (coupon.type === 'percentage') {
                discountAmount = (subTotal + oneTimeChargesTotal - limitedPeriodDiscountTotal - oneTimeDiscountTotal - everGreenDiscountTotal) * ((coupon.amount / 100) / 100);
            }
            invoiceDiscountTotal.total += discountAmount;
            if (coupon.duration === 'one_time') {
                invoiceDiscountTotal.oneTimeTotal += discountAmount;
            }
            if (coupon.duration === 'limited_period') {
                invoiceDiscountTotal.limitedPeriodTotal += discountAmount;
            }
            if (coupon.duration === 'forever') {
                invoiceDiscountTotal.everGreenTotal += discountAmount;
            }
        });

        total = subTotal + oneTimeChargesTotal - limitedPeriodDiscountTotal - oneTimeDiscountTotal - everGreenDiscountTotal + getters.tax;
        if (total < 0) {
            total = 0;
        }
        return {
            'total': total,
            'subTotal': subTotal,
            'invoiceDiscountTotal': invoiceDiscountTotal,
            'oneTimeChargesTotal': oneTimeChargesTotal,
            'limitedPeriodDiscountTotal': limitedPeriodDiscountTotal,
            'oneTimeDiscountTotal': oneTimeDiscountTotal,
            'everGreenDiscountTotal': everGreenDiscountTotal,
        }
    },
    hasFetchedEstimate( state ) {
        return state.hasFetchedEstimate;
    },
    shouldFetchEstimate( state ) {
        return state.userInfo.country === 'US' && state.userInfo.postalCode.trim().length >= 5;
    },
    hasLinkError( state ) {
        return state.linkError.active;
    },
    talon( state ) {
        return state.talon;
    },
    totalContacts( state, getters ) {
        let totalContacts = 0;
        const selectedAddons = state.cartInfo.selectedAddons.filter(item => item.itemId.toLowerCase().includes('contact'));
        const includedAddons = getters.includedAddons.filter(item => item.itemId.toLowerCase().includes('contact'));
        selectedAddons.forEach(item => {
            totalContacts += item.quantity;
        });
        includedAddons.forEach(item => {
            totalContacts += item.quantity;
        });
        return totalContacts;
    },
    totalUsers( state, getters ) {
        let totalUsers = 0;
        const selectedAddons = state.cartInfo.selectedAddons.filter(item => item.itemId.toLowerCase().includes('user'));
        const includedAddons = getters.includedAddons.filter(item => item.itemId.toLowerCase().includes('user'));
        selectedAddons.forEach(item => {
            totalUsers += item.quantity;
        });
        includedAddons.forEach(item => {
            totalUsers += item.quantity;
        });
        return totalUsers;
    },
    canSubmit( state ) {
        const keys = Object.keys(state.validation);
        const validation = state.validation;
        let canSubmit = true;
        keys.forEach(key => {
            if (validation.hasOwnProperty(key)) {
                if (!validation[key].valid) {
                    canSubmit = false;
                }
            }
        });
        return canSubmit;
    },
    canContactSales( state ) {
        const keys = [
            'firstName',
            'lastName',
            'email',
            'phone',
        ];
        const validation = state.validation;
        let canSubmit = true;
        keys.forEach(key => {
            if (validation.hasOwnProperty(key)) {
                if (!validation[key].valid && key !== 'tcAgreement') {
                    canSubmit = false;
                }
            }
        });

        if (state.purchaseInfo.showTc && !validation['tcAgreement'].valid) {
           canSubmit = false;
        }
        return canSubmit;
    },
    showContactSales( state, getters ) {
        return getters.billingPeriod != 'year' && !getters.isCuratedLink;
    },
};
