<template>
    <div class="field-grid">
        <InputField
            key="line1"
            v-model="line1"
            :value="line1"
            data-qa="line1-field"
            :valid="line1Valid"
            field-label="Address"
            field-type="text"
            required
            class="col-span-2 md:col-span-1"
        >
            <template v-slot:error>
                <p v-text="line1Error"></p>
            </template>
        </InputField>
        <InputField
            key="line2"
            v-model="line2"
            :value="line2"
            data-qa="line2-field"
            :valid="true"
            field-label="Apt/Suite/Other"
            field-type="text"
            class="col-span-2 md:col-span-1"
        >
        </InputField>
        <InputField
            key="city"
            v-model="city"
            :value="city"
            data-qa="city-field"
            :valid="cityValid"
            field-label="City"
            field-type="text"
            required
            class="col-span-2"
            :class="states ? 'md:col-span-1' : 'md:col-span-2'"
        >
            <template v-slot:error>
                <p v-text="cityError"></p>
            </template>
        </InputField>
        <Dropdown
            key="state-selection"
            v-model="state"
            :value="state"
            ref="stateDropdown"
            data-qa="state-selection"
            field-label="State"
            class="col-span-2 md:col-span-1"
            :valid="stateValid"
            :disabled="$store.getters.isSoftLoading"
            required
            has-default
            :options="states"
            v-if="states"
        >
            <template v-slot:error>
                <p v-text="stateError"></p>
            </template>
        </Dropdown>
        <Dropdown
            key="country-selection"
            v-model="country"
            :value="country"
            data-qa="country-selection"
            field-label="Country"
            class="col-span-2 md:col-span-1"
            :disabled="$store.getters.isSoftLoading"
            :valid="countryValid"
            required
            :options="countries"
        >
        </Dropdown>
        <InputField
            key="postalCode"
            v-model="postalCode"
            :value="postalCode"
            data-qa="postalCode-field"
            :valid="postalCodeValid"
            :disabled="$store.getters.isSoftLoading"
            field-label="Zip code"
            field-type="text"
            required
            class="col-span-2 md:col-span-1"
        >
            <template v-slot:error>
                <p v-text="postalCodeError"></p>
            </template>
        </InputField>
    </div>
</template>

<script>
import Dropdown from '@/BuyNow/Components/inputs/Dropdown.vue';
import InputField from '@/BuyNow/Components/inputs/InputField.vue';
import PhoneField from '@/BuyNow/Components/inputs/PhoneField.vue';

export default {
    components: {
        PhoneField,
        InputField,
        Dropdown,
    },
    data() {
        return {
        };
    },
    computed: {
        company: {
            get() {
                return this.$store.state.userInfo.company;
            },
            set(value) {
                this.$store.commit('updateUserInfo', { company: value });
            }
        },
        companyValid: {
            get() {
                return this.$store.state.validation.company.valid;
            },
            set(value) {
                this.$store.commit('updateValidation', { company: { valid: value, message: this.companyError }})
            }
        },
        companyError: {
            get() {
                return this.$store.state.validation.company.message;
            },
            set(value) {
                this.$store.commit('updateValidation', { company: { valid: this.companyValid, message: value }})
            }
        },
        line1: {
            get() {
                return this.$store.state.userInfo.line1;
            },
            set(value) {
                this.$store.commit('updateUserInfo', { line1: value });
            }
        },
        line1Valid: {
            get() {
                return this.$store.state.validation.line1.valid;
            },
            set(value) {
                this.$store.commit('updateValidation', { line1: { valid: value, message: this.line1Error }})
            }
        },
        line1Error: {
            get() {
                return this.$store.state.validation.line1.message;
            },
            set(value) {
                this.$store.commit('updateValidation', { line1: { valid: this.line1Valid, message: value }})
            }
        },
        line2: {
            get() {
                return this.$store.state.userInfo.line2;
            },
            set(val) {
                this.$store.commit('updateUserInfo', { line2: val });
            }
        },
        city: {
            get() {
                return this.$store.state.userInfo.city;
            },
            set(val) {
                this.$store.commit('updateUserInfo', { city: val });
            }
        },
        cityValid: {
            get() {
                return this.$store.state.validation.city.valid;
            },
            set(value) {
                this.$store.commit('updateValidation', { city: { valid: value, message: this.cityError }})
            }
        },
        cityError: {
            get() {
                return this.$store.state.validation.city.message;
            },
            set(value) {
                this.$store.commit('updateValidation', { city: { valid: this.cityValid, message: value }})
            }
        },
        postalCode: {
            get() {
                return this.$store.state.userInfo.postalCode;
            },
            set(val) {
                this.$store.commit('updateUserInfo', { postalCode: val });
            }
        },
        postalCodeValid: {
            get() {
                return this.$store.state.validation.postalCode.valid;
            },
            set(value) {
                this.$store.commit('updateValidation', { postalCode: { valid: value, message: this.postalCodeError }})
            }
        },
        postalCodeError: {
            get() {
                return this.$store.state.validation.postalCode.message;
            },
            set(value) {
                this.$store.commit('updateValidation', { postalCode: { valid: this.postalCodeValid, message: value }})
            }
        },
        country: {
            get() {
                return this.$store.state.userInfo.country;
            },
            set(val) {
                this.$store.commit('updateUserInfo', { country: val });
            }
        },
        countryValid: {
            get() {
                return true;
            },
            set(value) {
                this.$store.commit('updateValidation', { country: { valid: value, message: this.countryError }})
            }
        },
        countryError: {
            get() {
                return this.$store.state.validation.country.message;
            },
            set(value) {
                this.$store.commit('updateValidation', { country: { valid: this.countryValid, message: value }})
            }
        },
        state: {
            get() {
                return this.$store.state.userInfo.state;
            },
            set(val) {
                this.$store.commit('updateUserInfo', { state: val });
            }
        },
        stateValid: {
            get() {
                return this.$store.state.validation.state.valid;
            },
            set(value) {
                this.$store.commit('updateValidation', { state: { valid: value, message: this.stateError }})
            }
        },
        stateError: {
            get() {
                return this.$store.state.validation.state.message;
            },
            set(value) {
                this.$store.commit('updateValidation', { state: { valid: this.stateValid, message: value }})
            }
        },
        countries() {
            return window.countries.map((country) => {
                return {
                    value: country.code,
                    label: country.name,
                }
            });
        },
        states() {
            let foundCountry = window.countries.find((country) => {
                return country.code === this.country
            });

            if (foundCountry.states.length > 0) {
                let states = foundCountry.states.map((state) => {
                    return {
                        value: state.code,
                        label: state.name
                    }
                });
                return states;
            } else {
                return false;
            }
        }
    },
    watch: {
        // Form validation
        company(newVal, oldVal) {
            if (newVal == '') {
                this.companyValid = false;
                this.companyError = 'Company is required';
            } else {
                this.companyValid = true;
            }
        },
        line1(newVal, oldVal) {
            if (newVal == '') {
                this.line1Valid = false;
                this.line1Error = 'Address is required';
            } else {
                this.line1Valid = true;
            }
        },
        city(newVal, oldVal) {
            if (newVal == '') {
                this.cityValid = false;
                this.cityError = 'City is required';
            } else {
                this.cityValid = true;
            }
        },
        state(newVal, oldVal) {
            if (newVal == '') {
                this.stateValid = false;
                this.stateError = 'State is required';
            } else {
                this.stateValid = true;
            }
        },
        postalCode(newVal, oldVal) {
            if (newVal == '') {
                this.postalCodeValid = false;
                this.postalCodeError = 'Zip code is required';
            } else {
                this.postalCodeValid = true;
            }
        },
        country(newVal, oldVal) {
            if (newVal != oldVal) {
                this.$refs.stateDropdown.dirty = false;
                this.$store.commit('updateUserInfo', { state: '' });
            }
            if (newVal == '') {
                this.countryValid = false;
                this.countryError = 'Country is required';
            } else {
                this.countryValid = true;
            }
        }
    }
};
</script>

<style lang="postcss" scoped>
</style>
