<template>
    <article class="w-full col-span-2 shadow-card p-8 rounded-medium flex flex-col items-center">
        <IconIntegrations class="w-[65px] h-[65px] mx-auto mb-6" />
        <h1 class="text-lg font-medium mb-2 max-w-[620px]">Keap Developer Portal</h1>
        <p class="text-sm font-normal mb-6 max-w-[620px]">Explore our documentation</p>
        <Link class="mx-auto" href="https://developer.keap.com/" arrow right data-qa="link-error-cta">Developer portal</Link>
    </article>
</template>

<script>
import IconIntegrations from '@/../svg/Icon_Integrations.svg?component';
import Link from '@/Components/Link.vue';
import { SUPPORT_PHONE_NUMBER } from '@/Util/constants.js';

export default {
    components: {
        IconIntegrations,
        Link,
    },
    computed: {
        supportNumber() {
            return `tel:${SUPPORT_PHONE_NUMBER}`;
        },
        supportNumberDisplay() {
            return SUPPORT_PHONE_NUMBER;
        },
    }
};
</script>

<style lang="postcss" scoped>
</style>
