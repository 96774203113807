<template>
    <div class="field-grid">
        <div class="field-wrapper col-span-2" :class="{'field-wrapper--error': !cardNumberValid && cardNumberDirty}" ref="cc-number">
            <label class="field-label-v2">Card Number *</label>
            <div id="card-number" class="field-input-v2" :class="{'mb-1': !cardNumberValid}"><span class="icon-cc field-icon"></span></div>
            <div class="text-red-default text-xs" v-if="!cardNumberValid && cardNumberError != ''" aria-live="polite">
                {{ cardNumberError }}
            </div>
        </div>
        <div class="field-wrapper col-span-2 md:col-span-1" :class="{'field-wrapper--error': !cardExpireValid && cardExpireDirty}" ref="cc-number">
            <label class="field-label-v2">Expiration date *</label>
            <div id="card-expiry" class="field-input-v2 mb-1"></div>
            <div class="text-gray-2 font-normal text-2xs" v-if="cardExpireError == '' || (cardExpireValid && cardExpireDirty)">
                <p>MM / YY</p>
            </div>
            <div class="text-red-default text-2xs" v-if="!cardExpireValid && cardExpireError != ''" aria-live="polite">
                <p>{{ cardExpireError }}</p>
            </div>
        </div>
        <div class="field-wrapper col-span-2 md:col-span-1" :class="{'field-wrapper--error': !cardCvvValid && cardCvvDirty}" ref="cc-number">
            <label class="field-label-v2">CVV *</label>
            <div id="card-cvv" class="field-input-v2 mb-1"><span class="icon-info-circle field-icon" v-tooltip="{
                content: 'The security code located on the back of your credit/debit card.',
                theme: 'info-tooltip'
            }"></span></div>
            <div class="text-gray-2 font-normal text-2xs" v-if="cardCvvError == '' || (cardCvvValid && cardCvvDirty)">
                <p>CVV</p>
            </div>
            <div class="text-red-default text-2xs" v-if="!cardCvvValid && cardCvvError != ''" aria-live="polite">
                <p>{{ cardCvvError }}</p>
            </div>
        </div>
        <div class="col-span-2 text-center">
            <p class="text-2xs font-normal"><span class="icon-lock-locked"></span> Secure payment</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            cbInstance: {},
            cardComponent: {},
            cardNumberDirty: false,
            cardExpireDirty: false,
            cardCvvDirty: false,
        };
    },
    methods: {
        async tokenize() {
            const userInfo = this.$store.getters.userInfo;
            const additionalData = {
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                company: userInfo.company,
                billingAddr1: userInfo.line1,
                billingAddr2: userInfo.line2,
                billingCity: userInfo.city,
                billingStateCode: userInfo.state,
                billingZip: userInfo.postalCode,
                billingCountry: userInfo.country,
            };
            try {
                const { token } = await this.cardComponent.tokenize(additionalData);
                await this.$store.commit('updateUserInfo', { paymentToken: token });
            } catch(error) {
                window.Sentry.logInfo(error);

                this.$store.commit('setSubmitting', false);
                this.$store.commit('setError', {
                    active: true,
                    code: window.constants.errorCodes.cardError,
                    message: 'There was a problem with your card details.'
                });
            }
        }
    },
    expose: [
        'tokenize'
    ],
    computed: {
        cardNumberValid: {
            get() {
                return this.$store.state.validation.cardNumber.valid;
            },
            set(value) {
                this.$store.commit('updateValidation', { cardNumber: { valid: value, message: this.cardNumberError }})
            }
        },
        cardNumberError: {
            get() {
                return this.$store.state.validation.cardNumber.message;
            },
            set(value) {
                this.$store.commit('updateValidation', { cardNumber: { valid: this.cardNumberValid, message: value }})
            }
        },
        cardExpireValid: {
            get() {
                return this.$store.state.validation.cardExpire.valid;
            },
            set(value) {
                this.$store.commit('updateValidation', { cardExpire: { valid: value, message: this.cardExpireError }})
            }
        },
        cardExpireError: {
            get() {
                return this.$store.state.validation.cardExpire.message;
            },
            set(value) {
                this.$store.commit('updateValidation', { cardExpire: { valid: this.cardExpireValid, message: value }})
            }
        },
        cardCvvValid: {
            get() {
                return this.$store.state.validation.cardCvv.valid;
            },
            set(value) {
                this.$store.commit('updateValidation', { cardCvv: { valid: value, message: this.cardCvvError }})
            }
        },
        cardCvvError: {
            get() {
                return this.$store.state.validation.cardCvv.message;
            },
            set(value) {
                this.$store.commit('updateValidation', { cardCvv: { valid: this.cardCvvValid, message: value }})
            }
        },
    },
    async mounted() {
        this.cbInstance = Chargebee.init({
            site: Keap.env.VITE_CHARGEBEE_SITE,
            publishableKey: Keap.env.VITE_CHARGEBEE_KEY_PUBLIC,
        });

        await this.cbInstance.load('components');

        this.cardComponent = this.cbInstance.createComponent('card', {
            icon: false,
            placeholder: {
                number: '&nbsp;',
                expiry: '&nbsp;',
                cvv: '&nbsp;',
            },
            ariaLabel: {
                number: 'Credit Card',
                expiry: 'Expiration date',
                cvv: 'Security code'
            },
            classes: {
                focus: 'field-input-v2--focus'
            }
        });
        const cardNumberField = this.cardComponent.createField("number");
        cardNumberField.at("#card-number");
        const ccExpiryField = this.cardComponent.createField("expiry");
        ccExpiryField.at("#card-expiry");
        const ccCvvField = this.cardComponent.createField("cvv");
        ccCvvField.at("#card-cvv");
        cardNumberField.on('change', (currentState) => {
            if (currentState.error) {
                this.cardNumberDirty = true;
                this.cardNumberValid = false;
                this.cardNumberError = currentState.error.message;
            } else if (!currentState.error && currentState.complete) {
                this.cardNumberValid = true;
            }
        })
        ccExpiryField.on('change', (currentState) => {
            if (currentState.error) {
                this.cardExpireDirty = true;
                this.cardExpireValid = false;
                this.cardExpireError = currentState.error.message;
            } else if (!currentState.error && currentState.complete) {
                this.cardExpireValid = true;
            }
        })
        ccCvvField.on('change', (currentState) => {
            if (currentState.error) {
                this.cardCvvDirty = true;
                this.cardCvvValid = false;
                this.cardCvvError = currentState.error.message;
            } else if (!currentState.error && currentState.complete) {
                this.cardCvvValid = true;
            }
        })
        this.cardComponent.mount();
    },
};
</script>

<style lang="postcss" scoped>
</style>
