import axios from 'axios';

export default async (commit, getters) => {
    await commit('setSoftLoaded', true);
    let data;

    try {
        ({data} = await axios.get('/api/v1/plans', {
            params: {
                currency: getters.selectedCurrency,
                family: 'sandbox',
            }
        }));
        // Sort plans by order
        data.plans = data.plans.sort((a, b) => {
            return a.metadata.order - b.metadata.order;
        });

        if (!getters.cartInfo.selectedPlanId) {
            commit('updateCartInfo', { selectedPlanId: data.plans[0].id })
        }
        await commit('setPricing', data);
        await commit('setLoaded', true);
    } catch (error) {
        if (window.axios.isAxiosError(error)) {
            if (!!error.response) {
                const errorObj = error.response.data;
                if (getters.initialLoading) {
                    commit('setLinkError', {
                        active: true,
                        code: errorObj.error,
                    });
                    return;
                } else {
                    window.Sentry.logError(error, {server_event_id: error.response.data.eventId});
                    commit('setError', {
                        active: true,
                        code: errorObj.error,
                        message: errorObj.message
                    });
                }
            } else {
                window.Sentry.logError(error);
                commit('setError', {
                    active: true,
                    code: window.constants.errorCodes.generalError,
                    message: 'Unable to fetch pricing'
                });
            }
        } else if (!getters.hasActiveError) {
            window.Sentry.logError(error);
            commit('setError', {
                active: true,
                code: window.constants.errorCodes.generalError
            });
        }
    } finally {
        commit('setSoftLoaded', false);
    }

}
