export default {
    userInfo: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        currency: window.constants.currencies.USD,
        country: 'US',
        state: '',
        company: '',
        line1: '',
        line2: '',
        city: '',
        postalCode: '',
        paymentToken: '',
        subscriptionId: '',
        customerId: '',
        tenantId: '',
    },
    purchaseInfo: {
        planEdition: null,
        partnerAccountId: '',
        linkPostedBy: '',
        bundleId: '',
        sourceCode: '',
        partnerName: '',
        purchaseLinkId: '',
        customerId: '',
        subscriptionId: '',
        channel: '',
        showEtf: false,
        showTc: false,
        contacts: 0,
        users: 0,
        additionalMessages: null,
        textVoiceTier: null,
        kickstartId: '',
        token: '',
        totalPaid: 0,
        isUpgrading: false,
        isAddingSub: false,
        cardUsed: {
            last4: '',
            type: '',
        }
    },
    cartInfo: {
        pricing: [],
        coupons: [],
        selectedBillingPeriod: '1 year',
        billingPeriod: 'year',
        selectedPlanId: '',
        selectedAddons: [],
        selectedCharges: [],
        acceptedTc: false,
        originalQuantities: [],
        tempStorage: {
            attachments: []
        },
    },
    estimate: {},
    talon: '{\'version\': 6, \'status\': -1}',
    orderStatus: {
        submitting: false,
        submittingContactSales: false,
        success: false,
        contactedSales: false,
    },
    canSubmit: false,
    error: {
        active: false,
        message: '',
        code: 0,
        sentryId: '',
    },
    linkError: {
        active: false,
        message: '',
        code: 0,
    },
    validation: {
        // company: {
        //     valid: false,
        //     message: '',
        // },
        line1: {
            valid: false,
            message: '',
        },
        city: {
            valid: false,
            message: '',
        },
        state: {
            valid: false,
            message: '',
        },
        state: {
            valid: false,
            message: '',
        },
        country: {
            valid: true,
            message: '',
        },
        postalCode: {
            valid: false,
            message: '',
        },
        firstName: {
            valid: false,
            message: '',
        },
        lastName: {
            valid: false,
            message: '',
        },
        phone: {
            valid: false,
            message: '',
        },
        email: {
            valid: false,
            message: '',
        },
        cardNumber: {
            valid: false,
            message: '',
        },
        cardExpire: {
            valid: false,
            message: '',
        },
        cardCvv: {
            valid: false,
            message: '',
        },
        // tcAgreement: {
        //     valid: false,
        //     message: '',
        // }
    },
    currentStep: 0,
    hasFetchedEstimate: false,
    initialLoading: false,
    softLoading: false,
    loaded: false,
    tracking: {}
};
