<template>
    <button class="plan-card" :class="selectedPlan === plan.id ? 'plan-card--active' : ''" @click.prevent="togglePlan()" :data-qa="plan.id">
        <div class="plan-card__indicator"></div>
        <div class="plan-card__content">
            <h3 class="text-base leading-tight mb-1" data-qa="plan-name">{{ planName }}</h3>
            <p class="text-s mb-3" data-qa="plan-description" v-if="!!planDescription">{{ planDescription }}</p>
            <p class="text-xs mt-auto font-medium">Includes <span data-qa="plan-included-contacts">{{ includedContacts }}</span> contacts and <span data-qa="plan-included-users">{{ includedUsers }}</span> users</p>
        </div>
    </button>
</template>

<script>

export default {
    props: {
        plan: Object
    },
    methods: {
        async togglePlan() {
            if (!this.selectionDisabled) {
                this.selectedPlan = this.plan.id;
            }
        }
    },
    computed: {
        selectedPlan: {
            get() {
                return this.$store.getters.cartInfo.selectedPlanId;
            },
            set( value ) {
                this.$store.commit('updateCartInfo', { selectedPlanId: value });
            }
        },
        selectionDisabled() {
            if (this.$store.getters.isSoftLoading) {
                return true;
            }
        },
        planName() {
            return this.plan.metadata.title ?? this.plan.name;
        },
        planDescription() {
            return this.plan.metadata.description ?? this.plan.description;
        },
        includedContacts() {
            const includedContacts = this.plan.addons.find((addon) => {
                return addon.type == 'mandatory' && addon.itemId.toLowerCase().includes('contact');
            });
            return includedContacts.quantity;
        },
        includedUsers() {
            const includedUsers = this.plan.addons.find((addon) => {
                return addon.type == 'mandatory' && addon.itemId.toLowerCase().includes('user');
            });
            return includedUsers.quantity;
        },
    },
};
</script>

<style lang="postcss" scoped>
.plan-card {
    @apply col-span-1 border-gray-4 border-2 rounded-medium p-3 pr-5 flex flex-row items-stretch gap-2;

    &__content {
        @apply text-left flex flex-col;
    }

    &__indicator {
        @apply w-[22px] h-[22px] rounded-full border-2 border-gray-4 relative bg-white flex justify-center items-center flex-shrink-0;

        &::after {
            content: '';
            @apply w-[12px] h-[12px] bg-green-default absolute rounded-full opacity-0;
        }
    }

    &--active {
        @apply border-green-default bg-green-10;

        .plan-card__indicator {
            @apply border-green-default;

            &::after {
                @apply opacity-100;
            }
        }
    }
}
</style>
