<template>
    <main class="relative min-h-screen bg-white md:bg-gray-6 md:px-3">
        <LinkError v-if="$store.getters.hasLinkError"/>
        <div class="w-full h-full fixed bg-white top-0 left-0 z-50 flex justify-center items-center" v-if="isLoading" data-qa="page-loading">
            <Spinner class="mb-6 w-[80px] h-[80px]"/>
        </div>
        <div v-else>
            <div class="max-w-8xl mx-auto">
                <div class="w-full p-5 md:px-7 pt-7 md:pt-12 pb-4 mb-5">
                    <KeapLogo />
                </div>
            </div>
            <div class="flex flex-col md:flex-row gap-7 mx-auto max-w-2xl pb-14 md:pb-36" v-if="isLoaded && !$store.getters.orderComplete">
                <Customize ref="customize" v-if="currentStep === 0" class="transition-filter duration-200 ease-in-out" :class="{'blur-sm': isSoftLoading}" data-qa="customization-view" @submit-signup="submitSignup()"/>
            </div>
            <div class="flex flex-col md:flex-row gap-7 mx-auto max-w-2xl pb-14 md:pb-36" v-if="$store.getters.orderComplete">
                <ThankYou data-qa="oder-complete-view" />
            </div>
            <Errors />
            <Submitting v-if="$store.getters.isSubmitting"/>
        </div>
    </main>
</template>

<script>
import KeapLogo from '@/../svg/logo.svg?component';
import Spinner from '@/BuyNow/Components/Spinner.vue';
import Customize from '@/Sandbox/Pages/Customize.vue';
import ThankYou from '@/Sandbox/Pages/ThankYou.vue';
import LiveAccessibleArea from '@/BuyNow/Components/LiveAccessibleArea.vue';
import Errors from '@/Sandbox/Components/Modals/Errors.vue';
import Submitting from '@/Sandbox/Components/Modals/Submitting.vue';
import LinkError from '@/Sandbox/Components/LinkErrors/Error.vue';

export default {
    components: {
        KeapLogo,
        Spinner,
        LiveAccessibleArea,
        Customize,
        ThankYou,
        Errors,
        Submitting,
        LinkError,
    },
    methods: {
        afterEnter() {
            window.scrollTo(0, 0);
        }
    },
    async mounted() {
        this.$store.commit('setInitialLoading', true);
        if (!this.$store.getters.hasLinkError) {
            await this.$store.dispatch('fetchPricing');
        }
        this.$store.commit('setInitialLoading', false);
    },
    computed: {
        lineItems() {
            return [
                {
                    itemId: this.$store.getters.selectedPlanId,
                    quantity: 1,
                },
                ...this.$store.getters.cartInfo.selectedAddons,
                ...this.$store.getters.cartInfo.selectedCharges,
            ];
        },
        isLoaded() {
            return this.$store.getters.isLoaded;
        },
        currentStep: {
            get() {
                return this.$store.getters.currentStep;
            },
            set(step) {
                this.$store.commit('setCurrentStep', step);
            }
        },
        currency: {
            get() {
                return this.$store.getters.selectedCurrency;
            },
            set(value) {
                this.$store.commit('updateUserInfo', { currency: value })
            }
        },
        isLoading: {
            get() {
                return this.$store.getters.initialLoading;
            },
            set(isLoading) {
                this.$store.commit('setInitialLoading', isLoading);
            }
        },
        isSoftLoading: {
            get() {
                return this.$store.getters.isSoftLoading;
            },
            set(isLoading) {
                this.$store.commit('setSoftLoaded', isLoading);
            }
        },
        country() {
            return this.$store.getters.userInfo.country;
        },
        state() {
            return this.$store.getters.userInfo.state;
        },
        postalCode() {
            return this.$store.getters.userInfo.postalCode;
        },
    },
    watch: {
        isLoading() {
            if (this.isLoading) {
                document.body.classList.add('overflow-hidden');
            } else {
                document.body.classList.remove('overflow-hidden');
            }
        },
    }
};
</script>

<style lang="postcss" scoped>


.next-step-enter-active,
.next-step-leave-active {
    @apply transition-opacity delay-75 ease-in-out;
}

.next-step-enter-from,
.next-step-leave-to {
    @apply opacity-0;
}
</style>
