import submitSignup from '@/Sandbox/store/actions/submit-signup.js';
import fetchPricing from '@/Sandbox/store/actions/fetch-pricing.js';

export default {
    async submitSignup({ commit, getters }) {
        await submitSignup(commit, getters);
    },
    async fetchPricing({ commit, getters }) {
        await fetchPricing(commit, getters);
    },
};
